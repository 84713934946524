<template>
  <div class="notice wrapper">
    <a-spin :spinning="loading">
      <div class="">
        <a-empty v-if="notice.length == 0" description="暂无记录"></a-empty>
        <div v-else class="flex flex-between notice-box-main">
          <template v-for="(item, index) in notice" :key="index">
            <div
              class="notice-item flex flex-between"
              style="position: relative"
              @click="goToNoticeDetail(item.record_id)"
            >
              <div>
                <div class="notice-title">{{ item.enterprise_name }}</div>
                <div class="flex">
                  <div class="notice-park" v-if="item.park_name">
                    {{ item.park_name }}
                  </div>
                  <div class="notice-time">
                    {{ item.record_update_time || item.record_create_time }}
                  </div>
                </div>
              </div>
              
              <right-outlined />
              <div class="statustag" :style="{color:item.record_status == 1? '#1276cb' : '#212531'}">
                {{ getStatus(item.record_status) }}
              </div>
            </div>
          </template>
     
        </div>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="16"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['16', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
  </div>
</template>
  
  <script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
} from "@vue/runtime-core";
import { getDeclarationRecordList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserData } from "@/utils/storeData.js";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "Notice",
  components: {
    RightOutlined,
  },
  setup() {
    const pageData = reactive({
      notice: [],
      userData: computed(() => getUserData()),
      page: 1,
      limit: 16,
      total: 0,
      loading: false,
    });
    const getDeclarationRecordListFun = () => {
      pageData.loading = true;
      getDeclarationRecordList({
        page: pageData.page,
        limit: pageData.limit,
        park_id: pageData.userData?.user_park_id,
        user_id: pageData.userData?.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.notice = res.data.declaration_record;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const goToNoticeDetail = (_item) => {
      url.navigateTo("/declaration/decform", {
        record_id: encode(`${_item}`),
      });
    };
    watch(
      [
        () => pageData.userData.user_park_id,
        () => pageData.page,
        () => pageData.limit,
      ],
      (val) => {
        getDeclarationRecordListFun();
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const getStatus = (v) => {
      if (v == 0) {
        return "申报中";
      } else if (v == 1) {
        return "已完成";
      } else {
        return "已删除";
      }
    };
    return {
      ...toRefs(pageData),
      goToNoticeDetail,
      onShowSizeChange,
      getStatus,
    };
  },
});
</script>
  <style lang="less" scoped>
  .statustag{
    font-size: 18px;
    position: absolute;
    right: 30px;
    top: 45px;
    // transform: translateX(-50%);
    line-height: 1;
  }
.notice {
  padding-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .notice-box-main {
    flex-wrap: wrap;
    .notice-item {
      width: 570px;
      height: 110px;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;
      .notice-title {
        color: #212531;
        font-size: 18px;
        margin-bottom: 10px;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .notice-park {
        min-width: 80px;
        height: 22px;
        line-height: 22px;
        border-radius: 11px;
        border: solid 1px #1276cb;
        color: #1276cb;
        font-size: 14px;
        text-align: center;
        margin-right: 20px;
      }
      .notice-time {
        color: #212531;
        opacity: 0.7;
        font-size: 16px;
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
  